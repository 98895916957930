<template>
  <form v-on:submit.prevent="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">Add Role</h4>
    </div>

    <div class="modal-body">

      <div class="form-group">
        <label for="roleName">Name <span class="red-dot ml-3"></span></label>
        <input id="roleName" name="roleName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('roleName') }"
          autocomplete="off" placeholder="Enter name" v-model="role.name" v-validate="'required'" data-vv-as="Name" />
        <div class="invalid-feedback">The Name field is required.</div>
      </div>

      <div class="custom-control custom-radio">
        <input id="membershipType" name="roleMembershipType" type="radio" class="custom-control-input" :class="{ 'is-invalid': errors.has('roleMembershipType') }"
          :value="false" v-model="role.isMembershipType" />
        <label class="custom-control-label" for="membershipType">Non-Membership Type</label>
      </div>
      <div class="custom-control custom-radio mb-3">
        <input id="nonMembershipType" name="roleMembershipType" type="radio" class="custom-control-input" :class="{ 'is-invalid': errors.has('roleMembershipType') }"
          :value="true" v-model="role.isMembershipType" v-validate="'required'" data-vv-as="Membership Type" />
        <label class="custom-control-label" for="nonMembershipType">Membership Type</label>
        <div class="invalid-feedback">Please select the membership type</div>
      </div>

      <div class="my-2" v-if="showPurchaseFields">
        <h5> Subscriptions </h5>

        <div class="form-row mb-2">
          <div class="form-group col-md-6">
            <label for="subscriptionPrice">Purchase Price <span class="red-dot ml-3" v-if="isSubscriptionRequired"></span></label>
            <div class="input-group" :class="{ 'is-invalid': errors.has('subscriptionPrice') }">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input id="subscriptionPrice" name="subscriptionPrice" type="text" autocomplete="off"
                class="form-control" :class="{ 'is-invalid': errors.has('subscriptionPrice') }"
                @blur="updateToFixed($event.target.value)"
                v-model="role.subscriptionPrice" v-validate="{ required: isSubscriptionRequired, decimal: 2, min_value: 0.00 }" data-vv-as="Purchase Price" />
            </div>
            <div class="invalid-feedback">{{ errors.first('subscriptionPrice') }}</div>
          </div>

          <div class="form-group col-md-6">
            <label for="subscriptionDuration">Duration <span class="red-dot ml-3" v-if="isSubscriptionRequired"></span></label>
            <select id="subscriptionDuration" name="subscriptionDuration" class="custom-select" :class="{ 'is-invalid': errors.has('subscriptionDuration') }"
                autocomplete="off" v-model="role.subscriptionDuration" v-validate="{ required: isSubscriptionRequired }" data-vv-as="Subscription Duration">
              <option :value="null"></option>
              <option value="1">1 Month</option>
              <option value="3">3 Months</option>
              <option value="6">6 Months</option>
              <option value="12">12 Months</option>
            </select>
            <div class="invalid-feedback">The Subscription Duration field is required.</div>
          </div>
        </div>

        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" name="isMemberPurchasable" id="isMemberPurchasable" :value="true" v-model="role.isMemberPurchasable" />
            <label class="form-check-label" for="isMemberPurchasable">Show member for upgrade</label>
          </div>
        </div>
      </div>

      <h5> <label class="d-flex align-items-center" for="Permissions">Permissions</label> </h5>

      <div class="row">
      <div class="col-md-6" v-for="group in sortedPermissionGroup" :key="group.id">
      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-4 pt-0">{{ group.name }}</legend>
          <div class="col-sm-8">
            <div class="fcustom-control custom-checkbox" v-for="permission in sortedPermissions(group.permissions)" :key="permission.id">
              <input :id="'permission-' + permission.id" name="permission" type="checkbox" class="custom-control-input"  :value="permission.id" v-model="role.permissionIds" />
              <label class="custom-control-label" :for="'permission-' + permission.id">{{ permission.name }}</label>
            </div>
          </div>
        </div>
      </fieldset>
      </div>
      </div>

      <div class="mb-3">
        <input name="rolePermissionCount" type="hidden" class="form-control" :class="{ 'is-invalid': errors.has('rolePermissionCount') }"
          v-model="role.permissionIds.length" v-validate="'min_value:1'" data-vv-as="Permission" />
        <div class="invalid-feedback">Please select at least one permission</div>
      </div>

    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success">Save</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import orderBy from "lodash/orderBy";
import Toast from "@/utils/toast";

export default {
  name: 'role',
  data: function () {
    return {
      isEdit: false,
      role: {
        name: null,
        isMembershipType: false,
        permissionIds: [],
        isMemberPurchasable: null,
        subscriptionPrice: null,
        subscriptionDuration: null
      }
    }
  },
  computed: {
    isSubscriptionRequired () {
      if (this.role.isMemberPurchasable) return true;
      if (this.role.subscriptionPrice || this.role.subscriptionDuration) return true;
      return false;
    },
    showPurchaseFields() {
      return (this.getUseMembershipPayment || '').toUpperCase() === 'TRUE'
    },
    sortedPermissionGroup() {
      return orderBy(this.getPermissionGroups, "name");
    },
    ...mapGetters(["getPermissionGroups", "getRole", "getUseMembershipPayment"])
  },
  mounted: function() {
    this.role = { ...this.getRole };
    this.isEdit = !!this.role.id;
    this.$validator.reset();
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("bv-modal-role");
    },
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.saveRole({ data: this.role, isEdit: this.isEdit })
        .then(() => {
          Toast.success(this, "Successfully saved!");
          this.$bvModal.hide("bv-modal-role");
          this.closeModal();
        })
        .catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
    },
    sortedPermissions(permissions) {
      return orderBy(permissions, "name");
    },
    updateToFixed(value) {
      if (Number.parseFloat(value)) {
        this.role.subscriptionPrice = (parseInt(Number.parseFloat(value) * 100) / 100).toFixed(2); // trims numeric without rounding
      }
    },
    ...mapActions(["saveRole"])
  }
}
</script>
